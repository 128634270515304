import React from 'react';
import Select from 'react-select';
import './ChoiceList.css'; // Make sure to create this file for custom styles if needed
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#007bff' : '#ccc',
    boxShadow: state.isFocused ? '0 0 0 1px #007bff' : 'none',
    '&:hover': {
      borderColor: state.isFocused ? '#007bff' : '#aaa',
    },
    padding: '5px',
    borderRadius: '4px',
    fontSize: '16px',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '4px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '10px',
    backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#e6f7ff' : 'white',
    color: state.isSelected ? 'white' : 'black',
    '&:active': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: 'bold',
    color: '#007bff',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontStyle: 'italic',
    color: '#aaa',
  }),
};
const ChoiceList = ({ options, onChange }) => {
  return (
    <Select 
      options={options}
      onChange={onChange}
      styles={customStyles}
      classNamePrefix="custom-select"
    />
  );
};

export default ChoiceList;