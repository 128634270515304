import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditItemView from "../common/components/EditItemView";
import { useTranslation } from "../common/components/LocalizationProvider";
import LinkField from "../common/components/LinkField";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const UserPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const _userId = useSelector((state) => state.session.user._id);
  const _user = useSelector((state) => state.session.user);

  const [item, setItem] = useState();

  const validate = () => item.email ;
  return (
    <EditItemView
      endpoint="users"
      item={item}
      setItem={setItem}
      validate={validate}
      //  breadcrumbs={['settingsTitle', 'sharedDriver']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t("sharedUser")}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
            <TextField
                value={item.username || ""}
                onChange={(event) =>
                  setItem({ ...item, username: event.target.value })
                }
                // label={t('sharedName')}
                label={t('sharedUserName')}
              />
              <TextField
                value={item.email || ""}
                onChange={(event) =>
                  setItem({ ...item, email: event.target.value })
                }
                // label={t('sharedName')}
                label={t('sharedEmail')}
              />

              <TextField
                type="password"
                onChange={(event) =>
                  setItem({ ...item, password: event.target.value })
                }
                label={t("sharedPassword")}
              />
              {_user.role ==="superadmin"?
           <> <FormControlLabel
                control={
                  <Checkbox
                    checked={item.administrator}
                    onChange={(e) =>
                      setItem({ ...item, administrator: e.target.checked })
                    }
                  />
                  
                }
                label={t("sharedAdministrator")}
                // disabled={!item.administrator}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.sono_sys}
                    onChange={(e) =>
                      setItem({ ...item, sono_sys: e.target.checked })
                    }
                  />
                  
                }
                label={t("sharedSonoSys")}
                // disabled={!item.administrator}
              />
              </>  :<></>}
            </AccordionDetails>
          </Accordion>
          {item._id && (
         <>
         
         <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedAgencies')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <LinkField
                  endpointAll={`useragences?userId=${_userId}`}
                  endpointLinked={`users/${item._id}`}
                  endpointPermission={"userpermission/agence"}
                  baseId={item._id}
                  keyBase="_Id"
                  keyLink="_id"
                  label="agences"
                />

          
               
         
              </AccordionDetails>
            </Accordion>
            
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedDisplays')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <LinkField
                  endpointAll={`userdisplays?userId=${_userId}`}
                  endpointLinked={`users/${item._id}`}
                  endpointPermission={"userpermission/display"}
                  baseId={item._id}
                  keyBase="_Id"
                  keyLink="_id"
                  label="displays"
                />

          
               
         
              </AccordionDetails>
            </Accordion></>   
            
          )}
        </>
      )}
    </EditItemView>
  );
};

export default UserPage;
