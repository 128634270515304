import React from 'react';
import { makeStyles } from '@mui/styles';
import { ReactComponent as Logo } from '../resources/images/logo.svg';
import pubVid from "../resources/videos/emkaLED.mp4";
import logo from "../../src/resources/images/logo.svg"
import { useNavigate, useNavigation } from 'react-router-dom';
const useStyles = makeStyles(() => ({
  image: {
    alignSelf: 'center',
    maxWidth: '400px',
    maxHeight: '600x',
    width: 'auto',
    height: 'auto',
  },
}));

const LogoImage = ({ color }) => {
  const navigate = useNavigate()
  const classes = useStyles();
  const handleClick = () => {
    console.log('Image clicked!');
navigate('/home')  };
  return (
    <>
      { // <video alt="vidEmka" src={pubVid} autoPlay loop muted />
        
        <img className={classes.image} src={logo}  alt="Clickable" onClick={handleClick} style={{ cursor: 'pointer' }}/>

      }

    </>

  );
};

export default LogoImage;
