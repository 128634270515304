import { useEffect } from 'react';

const VisibilityTrigger = () => {
  useEffect(() => {
    const playSilentAudio = () => {
      const audio = new Audio();
      audio.src = 'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBAAAAABAAEAgD4AAIA+AAABAAgAZGF0YQAAAAA='; // Silent audio
      audio.muted = true;
      audio.play().catch(error => console.warn('Autoplay workaround failed:', error));
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        playSilentAudio();
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return null; // No UI for this component
};

export default VisibilityTrigger;