import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../../../common/components/ErrorDialog";
import './header.css';
import { useDispatch } from 'react-redux';
import AnimatedStrings from './animatedStrings';
export default function HeaderData({ size, lang, agncyFullNameFr,prop, agncyFullNameAr, logoUrl }) {

  const dispatch = useDispatch();
  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");
  const navigate = useNavigate();


  return (
    size == "sm" && lang == "ar" ? <header className="header">
      <h1 className="header--title" style={{width:"90%",textAlign:"center"}}> {agncyFullNameAr} </h1>
      <img alt="" className="logo" src={logoUrl} />
    </header> : size == "sm" && lang == "fr" ? <header className="header">
      <img alt="" className="logo" src={logoUrl} />
      <h1 style={{width:"90%",textAlign:"center"}} className="header--title"> {agncyFullNameFr} </h1>
    </header> : size == "lg" && prop == "p1" ? <header className="header">
      <h1 className="header--title"style={{width:"47%"}}> {agncyFullNameFr} </h1>
      <img alt="" className="logo" src={logoUrl} />
      <h1 className="header--title" style={{width:"47%", textAlign:"right"}}> {agncyFullNameAr} </h1>

    </header>:<header className="header">
      <img alt="" className="logo" src={logoUrl} />
      <AnimatedStrings string1={agncyFullNameAr} string2={agncyFullNameFr}/>
      <img alt="" className="logo" src={logoUrl} />


    </header>


  );
}
