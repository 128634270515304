import React, { useState, useEffect } from 'react';
import './animatedStrings.css';

const AnimatedStrings = ({ string1, string2 }) => {
  const [showFirst, setShowFirst] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirst(prevShowFirst => !prevShowFirst);
    }, 5000); // Change the interval duration as needed (2000ms = 2 seconds)

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="string-container">
      <div className={`string ${showFirst ? 'visible' : 'hidden'}`}>
        {string1}
      </div>
      <div className={`string ${showFirst ? 'hidden' : 'visible'}`}>
        {string2}
      </div>
    </div>
  );
}

export default AnimatedStrings;