import React, { useState, useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SoundUpload from './SoundUpload';
import { useTranslation } from "../common/components/LocalizationProvider";

const TripSoundUploadPage = ({ trip }) => {
  const [audioURL, setAudioURL] = useState(null);
  
const t =useTranslation()
  useEffect(() => {
    if (trip && trip.sound_name) {
      setAudioURL(`${process.env.REACT_APP_URL_NAME}/sound/${trip.sound_name}`);
    } else {
      setAudioURL(null);
    }
  }, [trip]);

  return (
    <>
      {trip && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t("sharedSoundFile")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SoundUpload tripId={trip._id} /> {/* Pass the tripId to SoundUpload */}
              {audioURL ? (
                <audio controls src={audioURL} style={{ marginTop: "10px" }}>
                  {t("sharedBrowserNotSupportThisAudio")}
                </audio>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  {t("sharedNoAudioAvailable")}
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </>
  );
};

export default TripSoundUploadPage;
