import React, { useState, useEffect, useRef } from 'react';
import './TextToSpeechUI.css';
import useTextToSpeech from '../ledDisplays/TextToSpeech';
import { useTranslation } from '../../common/components/LocalizationProvider';

const TextToSpeechUI = ({ auto_text }) => {
    const { speakText, stopSpeech, setUserInteracted } = useTextToSpeech();
    const [userText, setUserText] = useState('الرِّحْلَة فِي إتِّجَاهْ تُونِسْ سَتَكُونُ فِي غُضُونْ بِضْعِ دَقَائِقْ. الرًّجَاءْ مِنْ جَمِيعْ المُسَافِرِينْ التَّوَجُّهْ إلَى الحَافِلَه رَقْم 7080');
    const [loading, setLoading] = useState(false);
    const [audioPlaying, setAudioPlaying] = useState(false);
    const [generatedAudioUrl, setGeneratedAudioUrl] = useState(null);
    const textAreaRef = useRef(null);
const t = useTranslation()
    const handlePlayText = () => {
        setUserInteracted();
        if (audioPlaying) return;
        setAudioPlaying(true);

        speakText(userText || auto_text, setLoading, (audioUrl) => {
            setGeneratedAudioUrl(audioUrl); // Store URL for preview and download
            setAudioPlaying(false);
        });
    };

    const handleStopText = () => {
        stopSpeech();
        setAudioPlaying(false);
        setLoading(false);
    };

    const handleTextChange = (e) => {
        setUserText(e.target.value);
    };

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
    }, [userText]);

    return (
        <div className="tts-page">
            <div className="tts-card">
                <h1>{t("sharedTextToSpeech")}</h1>
                <textarea
                    ref={textAreaRef}
                    value={userText}
                    onChange={handleTextChange}
                    placeholder="أدخل النص الخاص بك هنا"
                    className="tts-textarea"
                />
                <div className="button-group">
                    <button
                        onClick={handlePlayText}
                        disabled={loading || audioPlaying}
                        className="tts-button"
                    >
                        ▶️ {t("sharedPlaySound")}
                    </button>
                </div>
            </div>

            {/* Audio Player and Download Link */}
            {generatedAudioUrl && (
                <div className="audio-preview">
                    <audio controls src={generatedAudioUrl} />
                    <br></br>
                    <a color='red'
                        href={generatedAudioUrl}
                        download={`generated_audio_${Date.now()}.mp3`} // Dynamic name with .mp3 extension
                        className="download-link" 
                    >
                        <h6 className='sounddownload'> 📥 {t("sharedDownloadSoundFile")}📥  </h6> 
                    </a>
                </div>
            )}

            {/* Overlay Spinner */}
            {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}
        </div>
    );
};

export default TextToSpeechUI;
