import React, { useEffect, useRef, useState } from "react";
import "../index.css"
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from "@mui/material";
import { useEffectAsync } from "../reactHelper";
import { getWithExpiry } from "../common/util/localstorage";
import SearchHeader, {
  filterByKeyword,
} from "../common/components/SearchHeader";

import CollectionActions from "../common/components/CollectionActions";
import {
  tripsActions,agenciesActions
} from "../store";
import TableShimmer from "../common/components/TableShimmer";
import { useDispatch, useSelector } from "react-redux";
import CollectionFab from "../settings/CollectionFab";
import { useNavigate } from "react-router-dom";
import CheckItem from "../common/components/CheckItem";

import ErrorDialog from "../common/components/ErrorDialog";
import DownloadTripsFab from "../settings/DownloadTripsFab";
import HorizontalScrollMenu from "./HorizontalScrollMenu";
import TextToAudio from "./TextToSpeech";
import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from '../common/components/LocalizationProvider';
import ScrollableTabs from "./scrollbar/ChoiceList";
import ChoiceList from "./scrollbar/ChoiceList";

const TripsPage = () => {

  const [selectedOption, setSelectedOption] = useState(null);

  const [options,setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [checkedValue, setCheckedValue] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const _trips = useSelector((state) => state.trips.items);
  const _userId = useSelector((state) => state.session.user._id);

  const _agencies = useSelector((state) => state.agencies.items);

const t= useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");

  const itemsRef = React.createRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startx, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);


  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };
  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/useragences?userId=${_userId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            console.log("Agencies::", data);
            setOptions([]);
            const newOption=[];
            for(var i=0; i< data.length ;i++){
              console.log("data agency:",data[i].agence_name)
              newOption.push({value:data[i].agence_name,label:data[i].agence_name})
            }
            setOptions(newOption);

            dispatch(agenciesActions.setItems(data));
          });
        setItems(await response.json());

        return;
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);
  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/trips?userId=${_userId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            console.log("data trips", data);
            dispatch(tripsActions.setItems(data));
          });
        setItems(await response.json());
        return
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);
  const handleChange =async selectedOption => {
    setSelectedOption(selectedOption);
    console.log(`Option selected:`, selectedOption);
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/${selectedOption.value}/trips`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            console.log("data trips", data);
            dispatch(tripsActions.setItems(data));
          });
        setItems(await response.json());
        return
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  };

  const handleItemClick = async (item, index) => {
    console.log('Clicked item:', item, 'at index:', index);

    setLoading(true);
    try {
      const response = await fetch(index == -1 ? `${process.env.REACT_APP_URL_NAME}/trips?userId=${_userId}` : `${process.env.REACT_APP_URL_NAME}/${item.agence_name}/trips`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            console.log("data trips", data);
            dispatch(tripsActions.setItems(data));
          });
        setItems(await response.json());
        return
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  };
  //console.log("itemms ", items);


  return (
    <>


      {/* _agencies && _agencies.length > 1 ?<> <HorizontalScrollMenu items={_agencies} onItemClick={handleItemClick} /><br /><br /><br /><br /><br /></> : <></>*/}

        <Typography
          variant="h2"
          component="h2"
          align="center"

        >
          {t('sharedTrips')}
        </Typography>
        {_agencies && _agencies.length > 1 ?  <ChoiceList options={options} onChange={handleChange} />:<></>}

        <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />

        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#192841" }}>
              <TableCell style={{ color:"#fff"}}>{t('sharedCodeLine')}</TableCell>
              <TableCell style={{ color:"#fff"}}>{t('sharedName')}</TableCell>

              <TableCell style={{ color:"#fff"}}>{t('sharedMessage')}</TableCell>
              <TableCell style={{ color:"#fff"}}>{t('sharedArDestination')}</TableCell>
              <TableCell style={{ color:"#fff"}}>{t('sharedFrDestination')}</TableCell>
              <TableCell style={{ color:"#fff"}}>{t('sharedDisplayLanguage')}</TableCell>
              <TableCell style={{ color:"#fff"}}>{t('sharedDepartureTime')}</TableCell>

              <TableCell style={{ color:"#fff"}}>{t('sharedAgencyName')}</TableCell>
              <TableCell style={{ color:"#fff"}}>{t('sharedStatus')}</TableCell>
              <TableCell style={{ color:"#fff"}}>{t('sharedIdColor')}</TableCell>
              <TableCell style={{ color:"#fff"}}>{t('sharedDestinationColor')}</TableCell>


              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!loading && _trips && _trips.length > 0 ? (
              _trips.filter(filterByKeyword(searchKeyword)).map((item) => (
                <TableRow key={item._id}>
                  <TableCell>{item.trip_id}</TableCell>
                  <TableCell>{item.trip_name}</TableCell>

                  <TableCell>{item.trip_message}</TableCell>
                  <TableCell>{item.trip_destination_Ar}</TableCell>
                  <TableCell>{item.trip_destination_Fr}</TableCell>
                  <TableCell>{item.trip_lang}</TableCell>
                  <TableCell>{item.trip_departure_time}</TableCell>

                  <TableCell><CheckItem id={item.agence_id} state={_agencies} label="agences" endpoint={`agences/${item.agence_id}`}></CheckItem></TableCell>

                  <TableCell>{item.trip_schedule_relationship}</TableCell>
                  <TableCell><CircleIcon style={{backgroundColor:"#000", color: item.trip_id_color==1?"red":item.trip_id_color==2?"blue":item.trip_id_color==3?"yellow":item.trip_id_color==4?"green":"white" }} /></TableCell>
                  <TableCell><CircleIcon style={{ backgroundColor:"#000",color: item.trip_dest_color==1?"red":item.trip_dest_color==2?"blue":item.trip_dest_color==3?"yellow":item.trip_dest_color==4?"green":"white" }} /></TableCell>



                  <TableCell padding="none">
                    <CollectionActions
                      itemId={item._id}
                      editPath="/settings/trip"
                      endpoint={`/trips?userId=${_userId}`}
                      removeendpoint={`/trips`}
                      action={tripsActions}
                      removable={true}
                      editable={true}

                    />

                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableShimmer columns={3} endAction />
            )}
          </TableBody>
        </Table>
        <CollectionFab editPath="/settings/trip" />
        <DownloadTripsFab />
        <ErrorDialog
          style={{ transform: "none" }}
          open={opening}
          errorMsg={errorMsg}
          onResult={handleOpeningResult}
        />
    </>
  );
};

export default TripsPage;
