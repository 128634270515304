import React, { useState } from 'react';
import axios from 'axios';
import { DropzoneArea } from 'react-mui-dropzone';
import { useTranslation } from '../common/components/LocalizationProvider';
import { Button } from '@mui/material';

const ImageUpload = ({agencyId}) => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const t = useTranslation();

  const onChange = (e) => {
    setFile(e[0]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', file);

    try {
      const res = await axios.post(`${process.env.REACT_APP_URL_NAME}/agences/${agencyId}/uploadimage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage(res.data.message);
    } catch (err) {
      if (err.response.status === 500) {
        setMessage('There was a problem with the server');
      } else {
        setMessage(err.response.data.message);
      }
    }
  };

  return (<>
  <DropzoneArea
                  dropzoneText={t('sharedDropzoneText')}
                  acceptedFiles={['image/png']}
                  filesLimit={1}
                  onChange={onChange}
                  showAlerts={false}
                  maxFileSize={500000}
                />
<Button variant="outlined" onClick={onSubmit}>{t('sharedUpload')} </Button>  </>
    
  
  );
};

export default ImageUpload;