// src/ContactForm.js

import React, { useState } from 'react';
import {
    TextField,
    Button,
    Box,
    Container,
    Typography,
    Grid,
    Paper
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCatch } from '../../reactHelper';
import { getWithExpiry } from '../../common/util/localstorage';
import ErrorDialog from '../../common/components/ErrorDialog';
import { useTranslation } from '../../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(8),
    },
    paper: {
        padding: theme.spacing(4),
        maxWidth: 600,
        width: '100%',
    },
    formTitle: {
        marginBottom: theme.spacing(3),
    },
    formField: {
        marginBottom: theme.spacing(2),
    },
    submitButton: {
        marginTop: theme.spacing(2),
    }
}));

const ContactForm = () => {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const t = useTranslation()
    const [opening, setOpening] = useState(false);
    const [errorMsg, setErrorMsg] = useState("Error !!");
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleOpeningResult = (opening) => {
        setOpening(false);
        if (opening) {
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission (e.g., send data to server)
        console.log(formData);
        handleSave();

    };
    const handleSave = useCatch(async () => {
        let url = `${process.env.REACT_APP_URL_NAME}/contact`;

        //console.log("ITEM:::::", item);
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
            },
            body: JSON.stringify(formData),
        });

        if (response.ok) {
            setFormData({
                name: '',
                email: '',
                message: ''
            });

            return;
        }
        if (response.status === 401) {
            setErrorMsg("UNAUTHORIZED");
            setOpening(true);
            //console.log("UNAUTHORIZED::", response.status);
        } else {
            setErrorMsg(await response.text());
            setOpening(true);
            throw Error(await response.text());
        }
    });
    return (<>
        <Container className={classes.formContainer}>
            <Paper className={classes.paper} elevation={3}>
                <Typography variant="h4" component="h1" className={classes.formTitle} align="center">
                    {t("sharedContactUs")}                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.formField}
                                fullWidth
                                label={t("sharedName")}
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.formField}
                                fullWidth
                                label={t("sharedEmail")}
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.formField}
                                fullWidth
                                label={t("sharedMessage")}
                                name="message"
                                multiline
                                rows={4}
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                className={classes.submitButton}
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                            >
                                {t("sharedSubmit")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
        <ErrorDialog
            style={{ transform: "none" }}
            open={opening}
            errorMsg={errorMsg}
            onResult={handleOpeningResult}
        />
    </>
    );
};

export default ContactForm;