import React from "react";
import productImg1 from "../../resources/images/B650P5/B650P5-2.png";
import productImg2 from "../../resources/images/B650P5/B650P5-3.png";
import productImg3 from "../../resources/images/B650P5TR/B650P5TR-3.png";
import productImg4 from "../../resources/images/B650P5TR/B650P5TR.png";
import productImg5 from "../../resources/images/B970RT/B970B5RT.png";
import productImg6 from "../../resources/images/B970RT/B970B5RT.png";
import productImg7 from "../../resources/images/afficheurs/B970P5X.png";
import productImg8 from "../../resources/images/afficheurs/B970P5X.png";

import productImg9 from "../../resources/images/BConnectP5.png";
import productImg10 from "../../resources/images/BConnectP5.png";

import productImg11 from "../../resources/images/B970P5.png";
import productImg12 from "../../resources/images/B970P5F.png";

import productImg13 from "../../resources/images/B970P5.png";
import productImg14 from "../../resources/images/B970P5F.png";

import productImg15 from "../../resources/images/B970P5RTB.png";
import productImg16 from "../../resources/images/B970P5RTF.png";

import productImg17 from "../../resources/images/BSmartP5.png";



import "./ProductStyles.css";
import ProductData from "./ProductData";
import NavBar from "./DashNavBar";
export default function Products() {
  return (
    <>
      <NavBar />
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div className="product">
        <h1>
          {" "}
          NOUS SOMMES LES LEADERS DU MARCHÉ DES SYSTÈMES D'AFFICHAGE POUR PASSAGERS      </h1>
        <p>
          Emka conçoit et fabrique des systèmes d’information voyageurs
          pour le secteur des transports publics. Des clients satisfaits dans plus de 80 pays
          du monde entier utilisent une gamme de produits EmkaTECH, attirés par des niveaux
          de qualité, de fiabilité et de service client élevés. La sécurité offerte par 35
          années d’expérience, l’indépendance financière et un programme
          de développement continu des produits constituent une garantie supplémentaire de la
          fiabilité de l’entreprise.
        </p>

        <ProductData
          className="first-des"
          heading="Girouette Bus “B650P5”"
          text="Une girouette installée à l’intérieur du bus, offrant une large gamme d’options d’affichage pour la destination ou les informations sur l’itinéraire du bus, avec un pupitre de contrôle, permet aux conducteurs de gérer les informations affichées."
          img1={productImg1}
          img2={productImg2}
        />
        <ProductData
          heading="Girouette Bus “B650P5RT”"
          className="first-des-reverse"
          text="Une girouette à l’intérieur du bus affichant les informations sur la destination et l’itinéraire en temps réel grâce à une connexion Internet 3G est une solution pratique pour les passagers."
          img1={productImg3}
          img2={productImg4}
        />
        <ProductData
          className="first-des"
          heading="Girouette Bus “B970P5RT”"
          text="Une girouette à l’intérieur du bus affichant les informations sur la destination et l’itinéraire en temps réel grâce à une connexion Internet 3G est une solution pratique pour les passagers."
          img1={productImg5}
          img2={productImg6}
        />
        <ProductData
          className="first-des-reverse"
          heading="Girouette Bus “B970P5”"
          text="Une girouette installée à l’intérieur du bus, offrant une large gamme d’options d’affichage pour la destination ou les informations sur l’itinéraire du bus, avec un pupitre de contrôle, permet aux conducteurs de gérer les informations affichées."
          img1={productImg7}
          img2={productImg8}
        />
    



      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>


  );
}
