import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TripsSound from "./TripsSound";
import { agencytripsActions } from "../store";
import { useEffectAsync } from "../reactHelper";

export default function SoundDisplays() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _agencytrips = useSelector((state) => state.agencytrips.items);
    const agency_name = useSelector((state) => state.agencies.items[0].agence_name);
    const [errorMsg, setErrorMsg] = useState("Error !!");

    useEffectAsync(async () => {
        setLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_URL_NAME}/${agency_name}/trips`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.ok) {
                const data = await response.json();
               
                dispatch(agencytripsActions.setItems(data));
                return;
            }
            if (response.status === 401) {
                setErrorMsg("UNAUTHORIZED");
                navigate("/login");
            } else {
                setErrorMsg(await response.text());
                throw Error(await response.text());
            }
        } finally {
            setLoading(false);
        }
    }, [agency_name, dispatch]);

    return (
        <>
            <TripsSound
                agencyName={agency_name}
            />
        </>
    );
}
