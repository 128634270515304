import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../../../common/components/ErrorDialog";

export default function Time({size, tempC}) {
  const [currentTime, setCurrentTime] = useState(getCurrentTime());
  const [temperature, setTemperature] = useState(tempC);
  const navigate = useNavigate()
  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");

  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };

  function getCurrentTime(separator = ':') {
    let today = new Date();

    let hr = String(today.getHours()).padStart(2, '0');
    let min = String(today.getMinutes()).padStart(2, '0');

    //console.log("CURENT TME",hr,":",min)

    return `${hr}${separator}${min}`;
  }
  useEffect(() => {
    // Update the current time every second
    const intervalId = setInterval(() => {
      setCurrentTime(getCurrentTime());
    }, 60000);

    // Cleanup the interval on unmount
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    // Update the current time every second
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(
          'https://api.weatherapi.com/v1/current.json?key=b1802813d5814afe819123308221504&q=34.721 11.016&aqi=no',
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
          }
        );
        if (response.ok) {
          response
            .clone()
            .json()
            .then((data) => {
              //console.log("TEMPERATURE:", data.current.temp_c)
              setTemperature(data.current.temp_c);
            });
          return;
        }
        if (response.status === 401) {
          setErrorMsg('UNAUTHORIZED');
          setOpening(true);
          //console.log("UNAUTHORIZED::", response.status);
          navigate('/login');
        } else {
          setErrorMsg(await response.text());
          setOpening(true);
          throw Error(await response.text());
        }
      } finally {
      }
    }, 60000);

    // Cleanup the interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  return (<>
  <div
      style={{
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '25%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: size === 'lg' ? 'space-between' : 'center',
        backgroundColor: '#021430',
        color: 'white',
        height: '100%',
      }}
    >
      <div>{currentTime}</div>
      {size === 'lg' ? (
        <div>{temperature ? temperature : tempC} °C</div>
      ) : (
        <></>
      )}
    </div>
    <ErrorDialog
    style={{ transform: "none" }}
    open={opening}
    errorMsg={errorMsg}
    onResult={handleOpeningResult}
  />
  </>
    
  );
}
