import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditItemView from "../common/components/EditItemView";
import { useTranslation } from "../common/components/LocalizationProvider";
import LinkItem from "../common/components/LinkItem";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const DisplayPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const _userId = useSelector((state) => state.session.user._id);
  const _connectedUser = useSelector((state) => state.session.user);

  const [item, setItem] = useState();

  const validate = () =>
    item &&
    item.display_name &&
    item.display_id &&
    item.trip_id 


  function callBack(attribut, value) {
   setItem({ ...item, trip_id: value })
    
  }
  return (
    <EditItemView
      endpoint="displays"
      item={item}
      setItem={setItem}
      validate={validate}
      //  breadcrumbs={['settingsTitle', 'sharedDriver']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t("sharedDisplay")}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              {_connectedUser.role==="superadmin"?<TextField
                value={item.display_id || ""}
                onChange={(event) =>
                  setItem({ ...item, display_id: event.target.value })
                }
                // label={t('sharedName')}
                label={t('sharedCodeLine')}
              />:<></>}
                   <TextField
                value={item.display_name || ""}
                onChange={(event) =>
                  setItem({ ...item, display_name: event.target.value })
                }
                // label={t('sharedName')}
                label={t('sharedDisplayName')}
              />
          
            

              <LinkItem
                endpointAll={`trips?userId=${_userId}`}
                linkedItem={item}
                baseId={item._id}
                keyBase="trip_id"
                keyLink="trips"
                label="TRIP"
                handleCallBack={callBack}
              />
           
             
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </EditItemView>
  );
};

export default DisplayPage;
