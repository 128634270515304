import React, { useRef, useState } from "react";
import "./Contact.css";
import { MdCall } from "react-icons/md";
import { BsFillChatDotsFill } from "react-icons/bs";
import {HiChatBubbleBottomCenter} from 'react-icons/hi2'
import { TextField, Button, Typography, Box } from "@mui/material";
import emailjs from '@emailjs/browser';
import ContactForm from "../../ContactUs/ContactForm";
import { useTranslation } from "../../../common/components/LocalizationProvider";

const ContactRes = () => {


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();
const t = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_obslklo', 'template_klnn07k', form.current, 'X7XWEZKvvjfcqsf8W')
    .then((result) => {
        console.log(result.text);
        setEmail("");
        setMessage("");
        setName("");
    }, (error) => {
        console.log(error.text);
    });    //
  };
  return (
    <div id="contact-us" className="c-wrapper">
      <div className="paddings innerWidth flexCenter c-container">
        {/* left side */}
        <div className="flexColStart c-left">
          <span className="orangeText"> {t('sharedOurContact')} </span>
          <span className="primaryText"> {t('sharedEasyToContactUs')}</span>
          <span className="secondaryText">
          {t('sharedContactMessage')}{" "}
          </span>

          <div className="flexColStart contactModes">
            {/* first row */}
            <div className="flexStart row">
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    <MdCall size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">{t('sharedCall')}</span>
                    <span className="secondaryText">00216 56 100 591</span>
                  </div>
                </div>
                <div className="flexCenter button"><a href="tel:0021656100591" >{t('sharedCallNow')}</a></div>
              </div>

              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    <BsFillChatDotsFill size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">{t('sharedChat')}</span>
                    <span className="secondaryText">00216 56 100 591</span>
                  </div>
                </div>
                <div className="flexCenter button"><a href="tel:0021656100591"> {t('sharedChatNow')}</a> </div>
              </div>
            </div>
            {/* second row */}
            <div className="flexStart row">
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    <BsFillChatDotsFill size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">{t('sharedVideoCallNow')}</span>
                    <span className="secondaryText">00216 56 100 591</span>
                  </div>
                </div>
                <div className="flexCenter button"><a href="tel:0021656100591"> {t('sharedVideoCallNow')}</a> </div>
              </div>

              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    <HiChatBubbleBottomCenter size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">{t('sharedChatNow')}</span>
                    <span className="secondaryText">00216 56 100 591</span>
                  </div>
                </div>
                <div className="flexCenter button"><a href="tel:0021656100591"> {t('sharedMessageNow')} </a> </div>
              </div>
            </div>
          </div>
        </div>
        {/* right side */}
        <div className="flexEnd c-right">
          <div className="image-container">
          <Box
           
            >
              <Box sx={{ maxWidth: 600, mx: "auto", p: 2 }}>
               <ContactForm />
              </Box>
            </Box>          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactRes;
