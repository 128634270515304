import React, { useEffect } from "react";
import DashNavBar from "../components/DashNavBar.js";
import Hero from "../components/Hero";
import backgroundImg from "../../resources/1.jpg";
import { useLocation } from "react-router-dom";
import ContactRes from "../components/Contact/Contact.js"

export default function Contact() {

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);

  return (
    <>
      <DashNavBar />
<br></br>
<br></br>
<br></br>
<br></br>
     <ContactRes />
    </>
  );
}
