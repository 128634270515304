import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, CardActions, Typography, IconButton, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import TripSoundUploadPage from './TripSoundUpload';
import { useTranslation } from '../common/components/LocalizationProvider';

const TripsSound = ({ agencyName }) => {
  const [trips, setTrips] = useState([]);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [error, setError] = useState('');
  const [updateTrip, setUpdateTrip] = useState(null); // Store the entire trip object
  const [openModal, setOpenModal] = useState(false); // State to control modal visibility
const t = useTranslation();
  // Fetch trips data
  const fetchTrips = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_NAME}/${agencyName}/trips`);
      setTrips(response.data);
    } catch (err) {
      setError('Failed to load trips');
      console.error(err);
    }
  };

  // Fetch trips on component mount
  useEffect(() => {
    fetchTrips();
  }, [agencyName]);

  // Handle play sound
  const playSound = (soundName) => {
    if (currentAudio) {
      currentAudio.pause();
    }

    const audio = new Audio(`${process.env.REACT_APP_URL_NAME}/sound/${soundName}`);
    audio.play();
    setCurrentAudio(audio);

    audio.onended = () => setCurrentAudio(null); // Reset after audio ends
  };

  // Handle delete sound
  const deleteSound = async (soundName, tripId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_URL_NAME}/sound/${soundName}`);
      setTrips(trips.map(trip => trip._id === tripId ? { ...trip, sound_name: null, sound_url: null } : trip));
    } catch (err) {
      setError('Failed to delete sound');
      console.error(err);
    }
  };

  // Open Modal for sound upload and pass the selected trip
  const handleOpenModal = (trip) => {
    setUpdateTrip(trip);  // Set the selected trip
    setOpenModal(true); // Show the modal
  };

  // Close Modal and refetch trips to update the list
  const handleCloseModal = () => {
    setOpenModal(false);
    setUpdateTrip(null); // Reset trip object when closing
    fetchTrips(); // Refetch the trips to get the updated list
  };

  // Update the trip after file upload
  const handleUpdateTrip = (updatedTrip) => {
    setTrips(trips.map(trip => trip._id === updatedTrip._id ? updatedTrip : trip)); // Update the trip in the state
    setOpenModal(false); // Close the modal after updating
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>{t("sharedTripsSoundForAgency")} {agencyName}</Typography>
      {error && <Typography color="error">{error}</Typography>}
      
      <Grid container spacing={3}>
        {trips.map((trip) => (
          <Grid item xs={12} sm={6} md={4} key={trip._id}>
            <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>{trip.trip_destination_Ar}</Typography>
                <Typography variant="body2" color="textSecondary">{t("sharedTripID")}: {trip.trip_id}</Typography>
                <Typography variant="body2" color="textSecondary">{t("sharedDepartureTime")}: {trip.trip_departure_time}</Typography>
              </CardContent>
              <CardActions style={{ justifyContent: 'flex-end' }}>
                {!trip.sound_name || !trip.sound_url ? (
                  <>
                    <Typography variant="body2" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
                      <VolumeOffIcon fontSize="small" style={{ marginRight: 4 }} />
                      {t("sharedNoAudioAvailable")}
                    </Typography>
                    <IconButton
                    
                      color="primary"
                      onClick={() => handleOpenModal(trip)}
                    >
                      <UploadIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton style={{color:"blue"}} color="primary" onClick={() => playSound(trip.sound_name)}>
                      <PlayArrowIcon />
                    </IconButton>
                    <IconButton style={{color:"red"}} color="primary" onClick={() => deleteSound(trip.sound_name, trip._id)}>
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                    style={{color:"green"}}
                      color="default"
                      onClick={() => handleOpenModal(trip)}
                    >
                      <UploadIcon />
                    </IconButton>
                  </>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal for Sound Upload */}
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth>
        <DialogTitle>{t("shareduploadSoundForTrip")}</DialogTitle>
        <DialogContent>
          {updateTrip && <TripSoundUploadPage trip={updateTrip} onUpdate={handleUpdateTrip} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            {t("sharedClose")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TripsSound;
