export const MenuItems = [
  {
    title: "Accueil",
    url: "/home",
    cName: "nav-links",
    icon: "fa-solid fa-house-user",
  },
  {
    title: "Nos Produits",
    url: "/produits",
    cName: "nav-links",
    icon: "fa-solid fa-circle-info",
  },
  {
    title: "Contactez-Nous",
    url: "/contactus",
    cName: "nav-links",
    icon: "fa-solid fa-house-user",
  },
  {
    title: "Login",
    url: "/",
    cName: "nav-links",
    icon: "fa-solid fa-address-book",
  },

];
