import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { agencytripsActions } from "../../store";
import { useEffectAsync } from "../../reactHelper";
import TextToSpeechUI from "./TextToSpeechUI";

export default function Speech() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _agencytrips = useSelector((state) => state.agencytrips.items);
    const agency_name = useSelector((state) => state.agencies.items[0].agence_name);
    const [autoText, setAutoText] = useState('');
    const [errorMsg, setErrorMsg] = useState("Error !!");

    useEffectAsync(async () => {
        setLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_URL_NAME}/${agency_name}/trips`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.ok) {
                const data = await response.json();
                let text = 'مَرْحَبًا بِحُرَفَائِنَا الكِرَامْ. يُسْعِدُنَا إعْلاَمُكُمْ بِأنَّ الرَّحَلاَتْ المُبَرْمَجَة...';
                data.forEach((elem, index) => {
                    text += ` الرِّحْلَة رَقْم ${index + 1} ... ${elem.trip_destination_Ar} ...سَتَكُونُ عَلَى السَّاعَةِ : ${elem.trip_departure_time} ...`;
                });
                setAutoText(text);
                dispatch(agencytripsActions.setItems(data));
                return;
            }
            if (response.status === 401) {
                setErrorMsg("UNAUTHORIZED");
                navigate("/login");
            } else {
                setErrorMsg(await response.text());
                throw Error(await response.text());
            }
        } finally {
            setLoading(false);
        }
    }, [agency_name, dispatch]);

    return (
        <>
            <TextToSpeechUI
                endpoint={`${process.env.REACT_APP_URL_NAME}/tts`}
                auto_text={autoText}
            />
        </>
    );
}
