import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { agenciesReducer as agencies } from './agencies';
import { claimsReducer as claims } from './claims';

import { tripsReducer as trips } from './trips';
import { agencytripsReducer as agencytrips } from './agencyTrips';

import { usersReducer as users } from './users';
import { errorsReducer as errors } from './errors';
import { sessionReducer as session } from './session';
import { displaysReducer as displays } from './displays';


import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage';

const reducer = combineReducers({
  session,
  agencies,
  trips,
  displays,
  users,
  errors,
  agencytrips,
  claims
});

export { agenciesActions } from './agencies';
export { tripsActions } from './trips';
export { agencytripsActions } from './agencyTrips';
export { displaysActions } from './displays';
export { usersActions } from './users';
export { errorsActions } from './errors';
export { sessionActions } from './session';
export { claimsActions } from './claims';





const persistConfig = {
  key: 'root',
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

//export const store = createStore(persistedReducer);
//export const persistor = persistStore(store);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);
