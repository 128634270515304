import React from "react";
import "./FooterStyles.css";

export default function DashFooter() {
  return (
    <div className="dashfooter">
      <div className="top">
        <div>
          <h1>LED DISPLAYS</h1>
          <p>choose your favourite led display</p>
        </div>
        <div>
          <a href="/">
            <i className="fa-brands fa-facebook-square"></i>
          </a>
          <a href="/">
            <i className="fa-brands fa-instagram-square"></i>
          </a>
          <a href="/">
            <i className="fa-brands fa-behance-square"></i>
          </a>
          <a href="/">
            <i className="fa-brands fa-twitter-square"></i>
          </a>
        </div>
      </div>
      <div className="bottom">
        <div>
          <h4>Project</h4>
          <a href="/home"> changelog</a>
          <a href="/home"> status</a>
          <a href="/home"> licence</a>
          <a href="/home"> all version</a>
        </div>
        <div>
          <h4>Community</h4>
          <a href="/home"> Project</a>
          <a href="/home"> GitHub</a>
          <a href="/home"> Issues</a>
          <a href="/home"> Twitter</a>
        </div>
        <div>
          <h4>Help</h4>
          <a href="/home"> Support</a>
          <a href="/home"> Troubleshouting</a>
          <a href="/home"> Contact Us</a>
          <a href="/home"> all version</a>
        </div>
        <div>
          <h4>Others</h4>
          <a href="/home"> Termes of Services</a>
          <a href="/home"> Privacy Policy</a>
          <a href="/home"> Licence</a>
        </div>
      </div>
    </div>
  );
}
