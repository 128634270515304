
import React from 'react';
import ContactForm from './ContactForm';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3f51b5',
        },
        secondary: {
            main: '#f50057',
        },
    },
});


const ContactPage = () => {
    

 

    return (
        <ThemeProvider theme={theme}>
        <CssBaseline />
        <ContactForm />
    </ThemeProvider>
    );
};

export default ContactPage;