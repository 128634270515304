import React from 'react';
import { Fab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DownloadIcon from '@mui/icons-material/Download';
import { useRestriction } from '../common/util/permissions';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      bottom: `calc(${theme.dimensions.bottomBarHeight}px + ${theme.spacing(2)})`,
    },
  },
}));

const DownloadTripsFab = ({ disabled }) => {
  const classes = useStyles();
  const _trips = useSelector((state) => state.trips.items);

  const downloadFile = () => {
    // is an object and I wrote it to file as
    // json

    // create file in browser
    const basefileName = "base";
    const datafileName = "data";


    //////

    var compteur = 0;
    var itemJson = "";
    var dataJson = "Mix/"+_trips[0].trip_id.toString()+"/70/"+_trips[0].trip_dest_color.toString()+"/"+_trips[0].trip_id_color.toString()+"/1"
    for (var i = 0; i < _trips.length; i++) {
      console.log(_trips[i].trip_id)
      itemJson += '{\n"lineIdA": "' +
        _trips[i].trip_id +
        '", \n"lineIdU": "' +
        _trips[i].trip_id +
        '", \n"lineNameFr": "' +
        _trips[i].trip_destination_Fr +
        '", \n"lineNameAr": "' +
        _trips[i].trip_destination_Ar +
        '", \n"IdView": "' +
        "1" +
        '"}';

      compteur += 1;
      if (compteur < _trips.length) itemJson += ",\n";
    }

    /////
    const json = JSON.stringify(_trips, null, 2);
    const blob = new Blob([itemJson], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = basefileName + ".txt";
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  const readonly = useRestriction('readonly');

  if (!disabled) {
    return (
      <Fab size="medium" color="primary" className={classes.fab} onClick={() => downloadFile()}>
        <DownloadIcon />
      </Fab>
    );
  }
  return '';
};

export default DownloadTripsFab;
