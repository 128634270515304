import React, { useState } from 'react';
import axios from 'axios';
import { DropzoneArea } from 'react-mui-dropzone';
import { useTranslation } from '../common/components/LocalizationProvider';
import { Button, Typography } from '@mui/material';

const SoundUpload = ({ tripId }) => {
  const [file, setFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [message, setMessage] = useState('');
  const [soundUrl, setSoundUrl] = useState(null); // New state to store the sound URL
  const t = useTranslation();

  const onChange = (files) => {
    if (files && files.length > 0) {
      const selectedFile = files[0];
      console.log("Selected file:", selectedFile); // Debugging log
      setFile(selectedFile);
      setPreviewURL(URL.createObjectURL(selectedFile));
    } else {
      setFile(null);
      setPreviewURL(null);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('audio', file);
    
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL_NAME}/trips/${tripId}/uploadsound`, 
        formData, 
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Base-URL': process.env.REACT_APP_URL_NAME,  // Send REACT_APP_URL_NAME in headers
          },
        }
      );
      setMessage(res.data);
    } catch (err) {
      if (err.response.status === 500) {
        setMessage('There was a problem with the server');
      } else {
        setMessage(err.response.data.message);
      }
    }
  };
  

  return (
    <>
      <DropzoneArea
        dropzoneText={t('sharedDropzoneText')}
        acceptedFiles={['audio/*']}
        filesLimit={1}
        onChange={onChange}
        showAlerts={false}
        maxFileSize={1000000}
      />
      {previewURL && (
        <div style={{ margin: '20px 0' }}>
          <Typography variant="subtitle1">Audio Preview:</Typography>
          <audio controls src={previewURL}>
            {t("sharedBrowserNotSupportThisAudio")}
          </audio>
        </div>
      )}
      <Button variant="outlined" onClick={onSubmit}>
        {t('sharedUpload')}
      </Button>
      {message && <Typography color="success" variant="body1">{message}</Typography>}
      
      {/* Display the download link if soundUrl is available */}
      {soundUrl && (
        <div style={{ marginTop: '10px' }}>
          <Typography variant="body1"> {t("sharedDownloadSoundFile")}</Typography>
          <a href={soundUrl} target="_blank" rel="noopener noreferrer">
            {soundUrl}
          </a>
        </div>
      )}
    </>
  );
};

export default SoundUpload;
