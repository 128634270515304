import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from "@mui/material";
import { useEffectAsync } from "../../reactHelper";
import { getWithExpiry } from "../../common/util/localstorage";
import SearchHeader, {
  filterByKeyword,
} from "../../common/components/SearchHeader";

import CollectionActions from "../../common/components/CollectionActions";
import { agenciesActions, claimsActions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import CollectionFab from "../../settings/CollectionFab";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../../common/components/ErrorDialog";
import SquareIcon from '@mui/icons-material/Square';
import { useTranslation } from '../../common/components/LocalizationProvider';
import TableShimmer from "../../common/components/TableShimmer";
const ClaimsPage = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const _claims = useSelector((state) => state.claims.items);
  //console.log("AGENCY FROM STORE", _agencies);
  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");
  const _userId = useSelector((state) => state.session.user._id);
  const _userRole= useSelector((state) => state.session.user.role);

  const t = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/contact`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            console.log("Agencies::", data);
            dispatch(claimsActions.setItems(data));
          });
        setItems(await response.json());
        return;
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);
  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };
  //console.log("items ", items);
  return (
    <>
      <Typography
        variant="h2"
        component="h2"
        align="center"
      >
          {t('sharedClaims')}
          </Typography>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />

      <Table className="user-claims-list">
        <TableHead>
          <TableRow style={{ backgroundColor: "#192841" }}>
            <TableCell  style={{ color:"#fff"}}>{t('sharedName')}</TableCell>
            <TableCell  style={{ color:"#fff"}}>{t('sharedEmail')}</TableCell>
            <TableCell  style={{ color:"#fff"}}>{t('sharedMessage')}</TableCell>
         


           
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && _claims &&  _claims.length > 0 ? (
            _claims.filter(filterByKeyword(searchKeyword)).map((item) => (
              <TableRow key={item._id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.message}</TableCell>
               

                <TableCell padding="none">
                  <CollectionActions
                    itemId={item._id}
                    endpoint="contact"
                    removeendpoint="contact"
                    action={claimsActions}
                    removable={true}
                    editable={false}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableShimmer columns={3} endAction />
          )}
        </TableBody>
      </Table>
      <ErrorDialog
        style={{ transform: "none" }}
        open={opening}
        errorMsg={errorMsg}
        onResult={handleOpeningResult}
      />
    </>
  );
};

export default ClaimsPage;
