import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from '../common/components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import { MuiColorInput } from 'mui-color-input'
import { DropzoneArea } from 'react-mui-dropzone';
import { useCatch } from '../reactHelper';
import axios from 'axios';
import ImageUpload from './ImageUpload';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const AgencyPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const _connectedUser = useSelector((state) => state.session.user);

  const [item, setItem] = useState();
  const [color, setColor] = React.useState('#ffffff')

  const validate = () =>
    item &&
    item.agence_id &&
    item.agence_name &&
    item.agence_full_name_Ar
    &&
    item.agence_full_name_Fr;
  const handleChange = (color) => {
    console.log(color)
    setColor(color)
    setItem({ ...item, agence_color: color })
  }





  return (
    <EditItemView
      endpoint="agences"
      item={item}
      setItem={setItem}
      validate={validate}
    //  breadcrumbs={['settingsTitle', 'sharedDriver']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t('sharedAgency')}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>

              {_connectedUser.role != "user"?<TextField
                value={item.agence_id || ''}
                onChange={(event) =>
                  setItem({ ...item, agence_id: event.target.value })
                }
                // label={t('sharedName')}
                label={t('sharedCodeLine')}
              />:<></>}
              <TextField
                value={item.agence_name || ''}
                onChange={(event) =>
                  setItem({ ...item, agence_name: event.target.value })
                }
                // label={t('sharedName')}
                label={t('sharedAgencyName')}
              />
              <TextField
                value={item.agence_full_name_Fr || ''}
                onChange={(event) =>
                  setItem({ ...item, agence_full_name_Fr: event.target.value })
                }
                // label={t('sharedName')}
                label={t('sharedFullNameFr')}
              />
               <TextField
                value={item.agence_full_name_Ar || ''}
                onChange={(event) =>
                  setItem({ ...item, agence_full_name_Ar: event.target.value })
                }
                // label={t('sharedName')}
                label={t('sharedFullNameAr')}
              />
              <TextField
                value={item.agence_message || ""}
                onChange={(event) =>
                  setItem({ ...item, agence_message: event.target.value })
                }
                // label={t('sharedName')}
                label={t('sharedMessage')}
              />
              <TextField
              type='number'
                value={item.agence_latitude || ""}
                onChange={(event) =>
                  setItem({ ...item, agence_latitude: event.target.value })
                }
                // label={t('sharedName')}
                label={t('sharedLat')}
              />
                   <TextField
              type='number'
                value={item.agence_longitude || ""}
                onChange={(event) =>
                  setItem({ ...item, agence_longitude: event.target.value })
                }
                // label={t('sharedName')}
                label={t('sharedLng')}
              />
              <MuiColorInput label={t('sharedAgencyPrimaryColor')} value={item.agence_color} onChange={handleChange} format="hex"
              />
              {item._id && (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">
                    {t('sharedAgency')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <ImageUpload agencyId={item._id} />


                  </AccordionDetails>
                </Accordion>
              )}
            </AccordionDetails>
          </Accordion>


        </>
      )}
    </EditItemView>
  );
};

export default AgencyPage;
