import { color } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../../common/components/ErrorDialog";

import Card from "./Card";
import "./noTrip.css";

import VisibilityTrigger from "./VisibilityTrigger";
import FocusAudioTrigger from "./FocusAudioTrigger";

export default function LedAgencyTripsDisplayer({
  endpoint,
  action,
  trips,
  sliceNumber,
  size,
  lang
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [sliceIndex, setSliceIndex] = useState(sliceNumber);

  const [reqTimer, setReqTimer] = useState(
    trips.length > 0 ? trips.length * 3000 : 60000
  );

  const [tripSliceIndex, setTripSliceIndex] = useState(0);

  const [basculeTimer, setBasculeTimer] = useState(
    trips.length > sliceIndex
      ? sliceIndex * 3000
      : trips.length > 0
      ? trips.length * 3000
      : 60000
  );
  const [tripsSlice, setTripsSlice] = useState(
    trips.length > 0
      ? trips.slice(0, trips.length > sliceIndex ? sliceIndex : trips.length)
      : trips
  );

  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");
 
 

  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };
  ////console.log("endpoint:", endpoint);

  /////////////////////////////////////////////////////
  //////////// REQUEST TIMER //////////////////////////
  /////////////////////////////////////////////////////
 

  useEffect(() => {
    let interval = setInterval(async () => {
      console.log("ENTER TO REQUEST TIMER");

      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL_NAME}/${endpoint}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          dispatch(action.setItems(data));
          //console.log("data from use effect ...........", trips);
          return;
        }
      } finally {
        setLoading(false);
      }
      console.log("REQUEST TIMER");
    }, reqTimer);
    return () => {
      clearInterval(interval);
    };
  }, [reqTimer, dispatch, endpoint, action, trips]);

  useEffect(() => {
    let interval = setInterval(() => {


      setTripSliceIndex((prev) =>
        prev >= trips.length ? sliceIndex : prev + sliceIndex
      );
      console.log("tripSliceIndex ", tripSliceIndex);
    }, basculeTimer);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {

    setTripsSlice(
      trips.length > 0
        ? trips.length - tripSliceIndex <= 0
          ? trips.slice(
              0,
              trips.length < sliceIndex ? trips.length : sliceIndex
            )
          : trips.length - tripSliceIndex >= sliceIndex
          ? trips.slice(tripSliceIndex, tripSliceIndex + sliceIndex)
          : trips.slice(tripSliceIndex, trips.length)
        : trips
    );

  }, [tripSliceIndex, trips, sliceIndex]);

 
  

  
 
  const tripsElements =
    trips && trips.length > 0 ? (
      tripsSlice.map((e, index) => {
        return (
          <Card
            key={index}
            number={sliceNumber}
            size={size}
            index={index}
            item={e}
           lang={lang}
           
           
           
          />
        );
      })
    ) : (
      <div
        style={{
          display: "flex",
          height: "100%",
          "align-items": "center",
          "justify-content": "center",
          color: "#808080",
        }}
      >
        <div className="noTrip"> لا يوجد رحلات </div>
      </div>
    );

  //console.log("trips from outside ...........", trips);

  /*
    if (state._kerkenahtrips.isLoading){
        return <h1>Loading ...</h1>
    }
     if (state._kerkenahtrips.isError){
        return <h1>Error ...</h1>
    }  
    */

  return (
    <>
      {tripsElements}
      <VisibilityTrigger />
      <FocusAudioTrigger />
      <ErrorDialog
        style={{ transform: "none" }}
        open={opening}
        errorMsg={errorMsg}
        onResult={handleOpeningResult}
      />
    </>
  );
}
