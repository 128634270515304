import React, { useEffect } from "react";
import DashNavBar from "../components/DashNavBar";
import Hero from "../components/Hero";
import backgroundImg from "../../resources/10.jpg";
import Products from "../components/Product";
import RecentWork from "../components/RecentWork";
import DashFooter from "../components/DashFooter"
import { useLocation } from "react-router-dom";
import PopularProducts from "../components/popularProducts/PopularProducts";
import PicSlider from "../components/slider/PicSlider";
import { useTranslation } from "../../common/components/LocalizationProvider";
import Contact from "./Contact";

export default function HomePage() {
  const t = useTranslation();
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);

  return (
    
    <>
      <DashNavBar />
      <Hero 
      cName="hero"
      heroImg={backgroundImg}
      title=""
      text= ""
      buttonText="CONNECT YOUR DISPLAYS"
      url="/home"
      btnClass="show"
      />
      <Products />
     <Contact />
      <DashFooter />
    </>
  );
}
