import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";

import Message from "./Message";
import Time from "./Time";
import { useEffectAsync } from "../../../reactHelper";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../../../common/components/ErrorDialog";
import TextToSpeech from "../../TextToSpeech";

export default function Footer({ size, agency_name, selectedAgency }) {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(
    "سفرة ممتـعة"
  );
  const [bgColor, setBgColor] = useState("#000000");

  const [direction, setDirection] = useState("right");

  const navigate = useNavigate();
  const [temperature, setTemperature] = useState();
  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");
  const [text, setText] = useState('Bon Voyage');
  const [voices, setVoices] = useState([]);
  const [error, setError] = useState(null);





  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };
  function isAlphabetic(str) {
    console.log("MSG::::::", str);
    const regex = /^[a-zA-Z\sÀ-ÿ]+$/;
    console.log( regex.test(str));

    return regex.test(str);
  }
  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_NAME}/agence/${agency_name}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        response
          .clone()
          .json()
          .then(async (data) => {

            setMsg(data[0].agence_message)
            setBgColor(data[0].agence_color)
            if (isAlphabetic(data[0].agence_message)) {
              setDirection("left");

            }
            else setDirection("right");



          });
        return
      } if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);
  useEffectAsync(async () => {
    // Update the current time every second
    try {
      console.log(selectedAgency.agence_latitude)
      const response = await fetch(
        `https://api.weatherapi.com/v1/current.json?key=b1802813d5814afe819123308221504&q=${selectedAgency.agence_latitude} ${selectedAgency.agence_latitude}&aqi=no`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            console.log("TEMPERATURE FOOTER:", data.current.temp_c);
            setTemperature(data.current.temp_c);
          });
        return
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
    }

    // Cleanup the interval on unmount
  }, []);

  return (
    <>
      <Message
        bgColor={bgColor}
        message={msg}
        tempC={temperature?temperature:29}
        direction={direction}
        size={size}
      />

      <ErrorDialog
        style={{ transform: "none" }}
        open={opening}
        errorMsg={errorMsg}
        onResult={handleOpeningResult}
      />
    </>
  );
}
