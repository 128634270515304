import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from "@mui/material";
import { useEffectAsync } from "../reactHelper";
import { getWithExpiry } from "../common/util/localstorage";
import SearchHeader, {
  filterByKeyword,
} from "../common/components/SearchHeader";

import CollectionActions from "../common/components/CollectionActions";
import {
  displaysActions,
} from "../store";
import TableShimmer from "../common/components/TableShimmer";
import { useDispatch, useSelector } from "react-redux";
import CollectionFab from "../settings/CollectionFab";
import { useNavigate } from "react-router-dom";
import CheckItem from "../common/components/CheckItem";
import IconButton from "@mui/material/IconButton";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import TripStopTimeAction from "../common/components/TripStopTimeAction";
import ErrorDialog from "../common/components/ErrorDialog";
import { useTranslation } from '../common/components/LocalizationProvider';
import CircleIcon from '@mui/icons-material/Circle';

const DisplaysPage = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [checkedValue, setCheckedValue] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const _displays = useSelector((state) => state.displays.items);
  const _userId= useSelector((state) => state.session.user._id);
  const _connectedUser= useSelector((state) => state.session.user);


  const _trips = useSelector((state) => state.trips.items);
const t = useTranslation()
 

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");

  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };
  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/userdisplays?userId=${_userId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            console.log("data trips", data);
            dispatch(displaysActions.setItems(data));
          });
        setItems(await response.json());
        return
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }    } finally {
      setLoading(false);
    }
  }, []);

  //console.log("itemms ", items);

  function getItemById(label, id, action, endpoint, state) {
    const item = state.filter((element) => element._id === id);
    if (item.length > 0) {
      return checkedValue;
    }

    return (
      <CheckItem label={label} id={id} action={action} endpoint={endpoint} />
    );
  }
 
  return (
    <>
        <Typography
        variant="h2"
        component="h2"
        align="center"
        
      >
        {t('sharedDisplays')}
      </Typography>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />

      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: "#192841" }}>
            <TableCell style={{ color:"#fff"}}>{t('sharedCodeLine')}</TableCell>
            <TableCell style={{ color:"#fff"}}>{t('sharedDisplayName')}</TableCell>
            <TableCell style={{ color:"#fff"}}>{t('sharedTrip')}</TableCell>
            <TableCell style={{ color:"#fff"}}>{t('sharedStatus')}</TableCell>
            <TableCell style={{ color:"#fff"}}>{t('sharedLatestUpdte')}</TableCell>


         

            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && _displays && _displays.length > 0 ? (
            _displays.filter(filterByKeyword(searchKeyword)).map((item) => (
              <TableRow key={item._id}>
                <TableCell>{item.display_id}</TableCell>
                <TableCell>{item.display_name}</TableCell>
               
                <TableCell><CheckItem id={item.trip_id} state={_trips} label="trips" endpoint={`trips/${item.trip_id}`}></CheckItem></TableCell>
                <TableCell><CircleIcon style={{backgroundColor:"#fff", color: item.display_status=="offline"?"red":"green" }} /></TableCell>
                <TableCell>{item.display_latestUpdate}</TableCell>


           

                <TableCell padding="none">
                  <CollectionActions
                    itemId={item._id}
                    editPath="/settings/display"
                    endpoint={`/displays?userId=${_userId}`}
                    removeendpoint={`/displays`}

                    action={displaysActions}
                    removable={_connectedUser.role==="superadmin"?true:false}
                    editable={true}

                  />
                  
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableShimmer columns={3} endAction />
          )}
        </TableBody>
      </Table>
      {_connectedUser.role==="superadmin"?<CollectionFab editPath="/settings/display" />:<></>}
      <ErrorDialog
        style={{ transform: "none" }}
        open={opening}
        errorMsg={errorMsg}
        onResult={handleOpeningResult}
      />
    </>
  );
};

export default DisplaysPage;
