// src/FocusAudioTrigger.js
import { useEffect } from 'react';

const FocusAudioTrigger = () => {
  useEffect(() => {
    const playSilentAudio = () => {
      const audio = new Audio();
      audio.src = 'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBAAAAABAAEAgD4AAIA+AAABAAgAZGF0YQAAAAA='; // Silent audio
      audio.muted = true;
      audio.play().catch(error => console.warn('Audio playback blocked:', error));
    };

    // Set up a delay to play audio on focus
    const handleFocus = () => {
      setTimeout(playSilentAudio, 200); // Attempt playback on focus after a delay
      window.removeEventListener('focus', handleFocus);
    };

    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  return null; // No UI for this component
};

export default FocusAudioTrigger;