import axios from 'axios';
import { useCallback, useRef } from 'react';

const useTextToSpeech = () => {
  const userInteracted = useRef(false);
  const audioRef = useRef(null);

  const setUserInteracted = () => {
    userInteracted.current = true;
  };

  const stopSpeech = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current = null;
    }
  };

  const speakText = useCallback(async (text, setLoading, onAudioGenerated) => {
    if (!userInteracted.current) {
      console.warn("Audio blocked until user interaction occurs.");
      return;
    }

    stopSpeech();

    try {
      setLoading(true);
      const response = await axios.post(
        `https://sound.emkatech.tn/api/tts`,
        { text, lang: 'ar' },
        {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob',
        }
      );

      const audioBlob = new Blob([response.data], { type: 'audio/mp3' });
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      audioRef.current = audio;

      audio.addEventListener('canplaythrough', () => {
        audio.play().catch((error) => console.error("Audio playback error:", error));
      });

      // Cleanup URL when playback ends
      audio.addEventListener('ended', () => URL.revokeObjectURL(audioUrl));

      audio.addEventListener('play', () => setLoading(false));

      // Provide generated audio URL through callback
      if (onAudioGenerated) onAudioGenerated(audioUrl);
    } catch (error) {
      console.error('Error with Text-to-Speech request:', error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return { speakText, stopSpeech, setUserInteracted };
};

export default useTextToSpeech;
