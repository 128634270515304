import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditItemView from "../common/components/EditItemView";
import { useTranslation } from "../common/components/LocalizationProvider";
import LinkItem from "../common/components/LinkItem";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import SoundUpload from './SoundUpload';

const formatTime = (time) => {
  return `${time.locale("en").format("HH:mm")}`;
};

const useStyles = makeStyles((theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const TripPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const _userId = useSelector((state) => state.session.user._id);

  const [item, setItem] = useState();
  const [audioURL, setAudioURL] = useState(null);
  const [departureValue, setDepartureValue] = useState(dayjs("2023-01-01T08:00"));

  const validate = () =>
    item &&
    item.trip_id &&
    item.trip_destination_Ar &&
    item.trip_destination_Fr &&
    item.agence_id;

  useEffect(() => {
    if (item && item.sound_name) {
      setAudioURL(`${process.env.REACT_APP_URL_NAME}/sound/${item.sound_name}`);
    } else {
      setAudioURL(null);
    }
  }, [item]);

  const handleDepartureTime = (e) => {
    const time = formatTime(e);
    setItem({ ...item, trip_departure_time: time });
  };

  const getColorName = (value) => {
    switch (value) {
      case 1: return "ROUGE";
      case 2: return "BLEU";
      case 3: return "JAUNE";
      case 4: return "VERT";
      case 5: return "BLANC";
      default: return "";
    }
  };

  const getColorValue = (value) => {
    switch (value) {
      case "ROUGE": return 1;
      case "BLEU": return 2;
      case "JAUNE": return 3;
      case "VERT": return 4;
      case "BLANC": return 5;
      default: return "";
    }
  };

  return (
    <EditItemView
      endpoint="trips"
      item={item}
      setItem={setItem}
      validate={validate}
    >
      {item && (
        <>
          <Accordion defaultExpanded >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t("sharedTrip")}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.trip_id || ""}
                onChange={(event) =>
                  setItem({ ...item, trip_id: event.target.value })
                }
                label={t("sharedCodeLine")}
              />
              <TextField
                value={item.trip_destination_Ar || ""}
                onChange={(event) =>
                  setItem({ ...item, trip_destination_Ar: event.target.value })
                }
                label={t("sharedArDestination")}
              />
              <TextField
                value={item.trip_destination_Fr || ""}
                onChange={(event) =>
                  setItem({ ...item, trip_destination_Fr: event.target.value })
                }
                label={t("sharedFrDestination")}
              />
              <TextField
                value={item.trip_message || ""}
                onChange={(event) =>
                  setItem({ ...item, trip_message: event.target.value })
                }
                label={t("sharedMessage")}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimeField
                  label={t("sharedDepartureTime")}
                  value={
                    item.trip_departure_time
                      ? dayjs(`2023-01-01T${item.trip_departure_time}`)
                      : departureValue
                  }
                  onChange={(newValue) => handleDepartureTime(newValue)}
                  format="HH:mm"
                />
              </LocalizationProvider>

              <FormControl>
                <InputLabel>{t("sharedDisplayLanguage")}</InputLabel>
                <Select
                  label={t("sharedDisplayLanguage")}
                  value={
                    item.trip_lang === "AR"
                      ? "AR"
                      : item.trip_lang === "FR"
                      ? "FR"
                      : "MIXTE"
                  }
                  onChange={(e) =>
                    setItem({ ...item, trip_lang: e.target.value })
                  }
                >
                  <MenuItem value="MIXTE">MIXTE</MenuItem>
                  <MenuItem value="FR">FR</MenuItem>
                  <MenuItem value="AR">AR</MenuItem>
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel>{t("sharedIdColor")}</InputLabel>
                <Select
                  label={t("sharedIdColor")}
                  value={getColorName(item.trip_id_color)}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      trip_id_color: getColorValue(e.target.value),
                    })
                  }
                >
                  <MenuItem value={getColorName(1)}>{t("sharedRed")}</MenuItem>
                  <MenuItem value={getColorName(2)}>{t("sharedBlue")}</MenuItem>
                  <MenuItem value={getColorName(3)}>{t("sharedYellow")}</MenuItem>
                  <MenuItem value={getColorName(4)}>{t("sharedGreen")}</MenuItem>
                  <MenuItem value={getColorName(5)}>{t("sharedWhite")}</MenuItem>
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel>{t("sharedDestinationColor")}</InputLabel>
                <Select
                  label={t("sharedDestinationColor")}
                  value={getColorName(item.trip_dest_color)}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      trip_dest_color: getColorValue(e.target.value),
                    })
                  }
                >
                  <MenuItem value={getColorName(1)}>{t("sharedRed")}</MenuItem>
                  <MenuItem value={getColorName(2)}>{t("sharedBlue")}</MenuItem>
                  <MenuItem value={getColorName(3)}>{t("sharedYellow")}</MenuItem>
                  <MenuItem value={getColorName(4)}>{t("sharedGreen")}</MenuItem>
                  <MenuItem value={getColorName(5)}>{t("sharedWhite")}</MenuItem>
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel>{t("sharedTripState")}</InputLabel>
                <Select
                  label={t("sharedTripState")}
                  value={
                    item.trip_schedule_relationship === "SCHEDULED"
                      ? "SCHEDULED"
                      : item.trip_schedule_relationship === "CANCELED"
                      ? "CANCELED"
                      : item.trip_schedule_relationship === "UNSCHEDULED"
                      ? "UNSCHEDULED"
                      : ""
                  }
                  onChange={(e) =>
                    setItem({
                      ...item,
                      trip_schedule_relationship: e.target.value,
                    })
                  }
                >
                  <MenuItem value="SCHEDULED">{t("sharedTripScheduled")}</MenuItem>
                  <MenuItem value="UNSCHEDULED">{t("sharedTripUnscheduled")}</MenuItem>
                  <MenuItem value="CANCELED">{t("sharedTripCanceled")}</MenuItem>
                </Select>
              </FormControl>

              <LinkItem
                endpointAll={`useragences?userId=${_userId}`}
                linkedItem={item}
                baseId={item._id}
                keyBase="agence_id"
                keyLink="agence_id"
                label="Agence"
                handleCallBack={(attribut, value) => setItem({ ...item, agence_id: value })}
              />
            </AccordionDetails>

            {/* item._id && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">Sound File</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <SoundUpload tripId={item._id} />
                  {audioURL ? (
                    <audio controls src={audioURL} style={{ marginTop: "10px" }}>
                      Your browser does not support the audio element.
                    </audio>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      No audio file available
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            ) */}
          </Accordion>
        </>
      )}
    </EditItemView>
  );
};

export default TripPage;
